import React from "react";
import DefaultNavigation from "./DefaultNavigation";
import UnauthorisedNavigation, { UNAUTHORISED } from "./UnauthorisedNavigation";
import NotFound, { NOT_FOUND } from "../screens/NotFound";
import { useAppSelector } from "../redux-app/hooks";
import Home, { HOME_SCREEN } from "../screens/Home";
import About, { ABOUT_SCREEN } from "../screens/About";
import Labour, { LABOUR_SCREEN } from "../screens/Labour";
import Traffic, { TRAFFIC_SCREEN } from "../screens/Traffic";
import Contact, { CONTACT_SCREEN } from "../screens/Contact";
import Register, { REGISTER_SCREEN } from "../screens/Register";
import { Platform } from "react-native";
import Profile, { PROFILE_SCREEN } from "../screens/Profile";
import EditProfile, { EDIT_PROFILE_SCREEN } from "../screens/EditProfile";

const Navigation = () => {
  const member = useAppSelector((state) => state.member);
  const screens: ScreenNavigation[] = [
    {
      name: HOME_SCREEN,
      component: Home,
      options: {
        headerShown: false,
        title: "Welcome to Allsafe Labour",
      },
    },
    {
      name: ABOUT_SCREEN,
      component: About,
      options: {
        headerShown: false,
        title: "About Allsafe Labour",
      },
    },
    {
      name: LABOUR_SCREEN,
      component: Labour,
      options: {
        headerShown: false,
        title: "Labour Hire Services",
      },
    },
    {
      name: CONTACT_SCREEN,
      component: Contact,
      options: {
        headerShown: false,
      },
    },
    {
      name: TRAFFIC_SCREEN,
      component: Traffic,
      options: {
        headerShown: false,
        title: "Traffic Solutions",
      },
    },

    {
      name: REGISTER_SCREEN,
      component: Register,
      options: {
        headerShown: Platform.OS === "web" ? false : true,
        headerTitle: "Register",
        title: "Register",
      },
    },
    {
      name: NOT_FOUND,
      component: NotFound,
    },
  ];

  const memberRoutes: ScreenNavigation[] = [
    {
      name: PROFILE_SCREEN,
      component: Profile,
      options: {
        headerShown: false,
        presentation: "modal",
        title: "Profile",
      },
    },
    {
      name: EDIT_PROFILE_SCREEN,
      component: EditProfile,
      options: {
        // headerShown: false,
        headerTitle: "Update Profile",
        title: "Update Profile",
      },
    },
  ];
  const defaultRoutes: ScreenNavigation[] = [
    {
      name: UNAUTHORISED,
      component: UnauthorisedNavigation,
      options: {
        headerShown: false,
        presentation: "modal",
      },
    },
  ];
  const routes = member ? memberRoutes : defaultRoutes;

  return <DefaultNavigation screens={[...screens, ...routes]} />;
};

export default Navigation;
