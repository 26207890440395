import { ScrollView, Text, useWindowDimensions, View } from "react-native";
import React, { useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import themes from "../constants/themes";
import { Formik, FormikHelpers, FormikValues } from "formik";
import FilledButton from "../components/FilledButton";
import TextField from "../components/TextField";
import * as yup from "yup";
import axios from "axios";
import endpoint from "../constants/endpoint";
import { useAppDispatch } from "../store";
import { setFullPageLoading } from "../reducers/fullPageLoading";

export const FORGOT_PASSWORD_SCREEN = "FORGOT_PASSWORD";

const ForgotPassword = () => {
  const width = useWindowDimensions().width;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const dispatch = useAppDispatch();

  const submit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    dispatch(setFullPageLoading(true));
    await axios
      .post(`${endpoint}/api/users/reset-password`, values)
      .then((response) => {
        setShowSuccessMessage(true);
        dispatch(setFullPageLoading(false));
        actions.resetForm();
      })
      .catch((err) => {
        actions.setErrors(err);
        dispatch(setFullPageLoading(false));
      });
  };
  const initialValues: FormikValues = {
    email: "",
  };

  const schema = {
    email: yup.string().email("Invalid email").required("Email is required"),
  };

  const validation = yup.object().shape(schema);

  return (
    <SafeAreaView
      edges={["bottom"]}
      style={{ flex: 1, backgroundColor: themes.defaultBackgroundColor }}
    >
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        <View
          style={{
            marginHorizontal: width > 800 ? "20%" : 20,
            flex: 1,
          }}
        >
          <Text
            style={{ fontSize: 40, textAlign: "center", marginVertical: 40 }}
          >
            FORGOT PASSWORD
          </Text>
          <Formik
            validationSchema={validation}
            onSubmit={submit}
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {(props: any) => {
              return (
                <>
                  <TextField
                    name="email"
                    keyboardType="email-address"
                    autoFocus
                  />
                  {showSuccessMessage && (
                    <Text style={{ color: "green" }}>
                      Reset password link is sent to your email.
                    </Text>
                  )}

                  <FilledButton
                    onPress={props.handleSubmit}
                    label="Send email"
                  />
                </>
              );
            }}
          </Formik>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ForgotPassword;
