import React from "react";
import { View, Text, StyleSheet, useWindowDimensions } from "react-native";
import Touchable from "./Touchable";
import { Icon } from "@rneui/themed";
import { useNavigation } from "@react-navigation/native";
import { UNAUTHORISED } from "../navigation/UnauthorisedNavigation";
import { useAppSelector } from "../redux-app/hooks";
import { PROFILE_SCREEN } from "../screens/Profile";
import { LOGIN_SCREEN } from "../screens/Login";

const LoginButton = () => {
  const navigation = useNavigation<UseNavigationProps>();
  const member = useAppSelector((state) => state.member);
  const width = useWindowDimensions().width;

  return (
    <Touchable
      onPress={() =>
        member
          ? navigation.navigate(PROFILE_SCREEN)
          : navigation.navigate(UNAUTHORISED, { screen: LOGIN_SCREEN })
      }
    >
      <View style={styles.container}>
        <Icon name="person" size={20} />
        {width > 800 && (
          <Text style={styles.text}>{member ? "Profile" : "Login"}</Text>
        )}
      </View>
    </Touchable>
  );
};

export default LoginButton;

const styles = StyleSheet.create({
  text: {
    fontSize: 12,
    fontWeight: "bold",
    paddingLeft: 5,
  },
  container: {
    flexDirection: "row",
    alignItems: "center",
    borderWidth: 0.5,
    borderColor: "silver",
    borderRadius: 5,
    padding: 5,
  },
});
