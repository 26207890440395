import React from "react";
import {
  View,
  Text,
  useWindowDimensions,
  StyleSheet,
  ScrollView,
} from "react-native";
import ImageTitle from "../components/ImageTitle";
import Footer from "../components/Footer";
import { WrappedMap } from "../components/WrappedMap";
import ContactDetails from "../components/ContactDetails";
import ContactForm from "../components/ContactForm";

export const CONTACT_SCREEN = "CONTACT";
const Contact = () => {
  const width = useWindowDimensions().width;
  return (
    <ScrollView style={styles.root}>
      <ImageTitle text="CONTACT US" uri={require("../assets/helmet.jpg")} />
      <View
        style={{
          marginHorizontal: width > 800 ? "10%" : 20,
          marginVertical: 20,
        }}
      >
        <Text style={{ textAlign: "center", fontSize: 35 }}>
          Have a question? We have the answers.
        </Text>
        <Text style={{ fontSize: 18, marginTop: 18 }}>
          Our friendly customer service team are ready to assist you. Fill out
          the form down below and we will get back to you as soon as possible.
        </Text>
      </View>

      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={
          <div
            className="w-100 h-100 "
            style={{
              marginRight: width > 800 ? "10%" : 0,
              marginLeft: width > 800 ? "10%" : 0,
              marginTop: 25,
              marginBottom: 25,
            }}
          />
        }
        mapElement={
          <div
            style={{
              height: 300,
              width: "100%",
            }}
          />
        }
      />

      <View
        style={{
          marginHorizontal: width > 800 ? "10%" : 20,
          rowGap: 10,
          marginVertical: 10,
          flexDirection: width > 800 ? "row" : "column",
        }}
      >
        <ContactDetails />
        <View
          style={{
            flex: 2,
            backgroundColor: "whitesmoke",
            borderRadius: 5,
            paddingVertical: width > 800 ? 50 : 10,
            paddingHorizontal: width > 800 ? 0 : 10,
            marginTop: width > 800 ? 0 : 100,
          }}
        >
          <ContactForm whiteBackground={true} />
        </View>
      </View>

      <Footer />
    </ScrollView>
  );
};

export default Contact;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: "white",
  },
});
