import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormikValues } from "formik";
import axios from "axios";
import endpoint from "../constants/endpoint";
import { setToken } from "../reducers/token";
import { setMember } from "../reducers/member";

export const logout = createAsyncThunk(
  "logout",
  async (data: undefined, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setToken(null));
      dispatch(setMember(null));
    } catch (e) {
      console.warn("logout thunk failed", e);
      return rejectWithValue(e);
    }
  }
);
