import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import endpoint from "../constants/endpoint";

export const deleteFile = createAsyncThunk(
  "deleteFile",
  async (data: string, { rejectWithValue }) => {
    try {
      console.log(data);
      return await axios
        .post(`${endpoint}/api/files/delete`, { url: data })
        .catch((err) => {
          console.log(err);
          throw err.response.data;
        });
    } catch (e) {
      console.warn("upload thunk failed", e);
      return rejectWithValue(e);
    }
  }
);
