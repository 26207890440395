import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormikValues } from "formik";
import axios from "axios";
import endpoint from "../constants/endpoint";

export const register = createAsyncThunk(
  "register",
  async (data: FormikValues, { dispatch, getState, rejectWithValue }) => {
    try {
      return await axios
        .post(`${endpoint}/api/users/register`, data)
        .catch((err) => {
          throw err.response.data;
        });
    } catch (e) {
      console.warn("register thunk failed", e);
      return rejectWithValue(e);
    }
  }
);
