import React from "react";
import { View, Text, useWindowDimensions, Image, Linking } from "react-native";
import { HOME_SCREEN } from "../screens/Home";
import Touchable from "./Touchable";
import { useNavigation } from "@react-navigation/native";
import { ABOUT_SCREEN } from "../screens/About";
import { TRAFFIC_SCREEN } from "../screens/Traffic";
import { LABOUR_SCREEN } from "../screens/Labour";
import { Icon } from "@rneui/themed";

const Footer = () => {
  const width = useWindowDimensions().width;
  const navigation = useNavigation<UseNavigationProps>();
  const menu = [
    {
      text: "About",
      onPress: () => navigation.navigate(ABOUT_SCREEN),
    },
    {
      text: "Labour",
      onPress: () => navigation.navigate(LABOUR_SCREEN),
    },
    {
      text: "Traffic",
      onPress: () => navigation.navigate(TRAFFIC_SCREEN),
    },
    {
      text: "Career",
      onPress: () => navigation.navigate(ABOUT_SCREEN),
    },
  ];

  return (
    <View
      style={{
        flex: 1,
        paddingTop: 50,
        borderTopWidth: 1,
        borderTopColor: "whitesmoke",
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: width > 800 ? "row" : "column",
          marginHorizontal: width > 800 ? "10%" : 30,
          marginVertical: "10",
        }}
      >
        <View style={{ flex: 1 }}>
          <Touchable
            containerStyle={{
              paddingRight: 10,
            }}
            onPress={() => navigation.navigate(HOME_SCREEN)}
          >
            <Image
              source={{
                uri: require("../assets/logo.png"),
                height: 40,
                width: 180,
              }}
            />
          </Touchable>
          <Text style={{ fontWeight: "bold", marginTop: 20 }}>
            ALLSAFE LABOUR
          </Text>
          <Text style={{ fontSize: 14, marginTop: 10 }}>
            Contact AllSafe Labour Hire today to connect with our highly skilled
            team of laborers and tradespeople.
          </Text>
        </View>
        <View
          style={{
            flex: 2,
            flexDirection: width > 800 ? "row" : "column",
          }}
        >
          <View
            style={{
              flex: 1,
              alignItems: width > 800 ? "center" : "flex-start",
            }}
          >
            <Text style={{ fontWeight: "bold", marginBottom: 10 }}>
              USEFUL LINKS
            </Text>
            {menu.map((category) => (
              <Touchable
                key={category.text}
                onPress={() => {
                  if (category.onPress) {
                    category.onPress();
                  } else {
                    // navigation.navigate(LISTINGS, {
                    //   category: category.category,
                    // });
                  }
                }}
              >
                <View style={{ paddingVertical: 10 }}>
                  <Text
                    style={{
                      fontSize: 15,
                      // fontWeight: "bold",
                    }}
                  >
                    {category.text}
                  </Text>
                </View>
              </Touchable>
            ))}
          </View>
          <View style={{ flex: 1, marginTop: width < 800 ? 50 : 0 }}>
            <Text style={{ fontWeight: "bold" }}>CONTACT</Text>
            <View style={{ marginTop: 20 }}>
              <View style={{ flexDirection: "row" }}>
                <Icon name="home" size={20} color="#f49312" />
                <View style={{ marginTop: -2, marginLeft: 10 }}>
                  <Text style={{ marginVertical: 5 }}>Level 2/1</Text>
                  <Text style={{ marginVertical: 5 }}>Southbank Blvd</Text>
                  <Text style={{ marginVertical: 5 }}>
                    Southbank Victoria 3006
                  </Text>
                </View>
              </View>
              <Touchable
                onPress={() =>
                  Linking.openURL("mailto:admin@allsafelabour.com")
                }
              >
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Icon name="email" size={20} color="#f49312" />
                  <Text
                    style={{
                      marginVertical: 5,
                      marginLeft: 10,
                    }}
                  >
                    admin@allsafelabour.com.au
                  </Text>
                </View>
              </Touchable>
              <Touchable onPress={() => Linking.openURL("tel:+61399824408")}>
                <View
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    marginTop: 10,
                  }}
                >
                  <Icon name="phone" size={20} color="#f49312" />
                  <Text
                    style={{
                      marginVertical: 5,
                      marginLeft: 10,
                    }}
                  >
                    +61 3 99824408
                  </Text>
                </View>
              </Touchable>
            </View>
          </View>
        </View>
      </View>
      <Text
        style={{
          textAlign: "center",
          marginBottom: 20,
          marginTop: width > 800 ? 20 : 50,
        }}
      >
        © {new Date().getFullYear()} Copyright All rights reserved
      </Text>
    </View>
  );
};

export default Footer;
