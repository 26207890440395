//@ts-nocheck

import React from "react";
import {
  withScriptjs,
  GoogleMap,
  withGoogleMap,
  Marker,
} from "react-google-maps";

function Map() {
  return (
    <GoogleMap
      defaultZoom={12}
      defaultCenter={{ lat: -37.82082787088611, lng: 144.96285075431692 }}
    >
      <Marker position={{ lat: -37.82082787088611, lng: 144.96285075431692 }} />
    </GoogleMap>
  );
}

export const WrappedMap = withScriptjs(withGoogleMap(Map));
