import { createSlice, isAnyOf, PayloadAction } from "@reduxjs/toolkit";
import { login } from "../thunks/login";
import { register } from "../thunks/register";

const initialState = false;

const slice = createSlice({
  name: "fullPageLoading",
  initialState,
  reducers: {
    setFullPageLoading(state, action: PayloadAction<boolean>) {
      return action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      isAnyOf(
        register.fulfilled,
        register.rejected,
        login.fulfilled,
        login.rejected
      ),
      (state, action) => {
        return false;
      }
    );
  },
});

export const { setFullPageLoading } = slice.actions;

export default slice.reducer;
