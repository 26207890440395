import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormikValues } from "formik";
import axios from "axios";
import endpoint from "../constants/endpoint";
import { setMember } from "../reducers/member";

export const updateProfile = createAsyncThunk(
  "updateProfile",
  async (data: FormikValues, { rejectWithValue, dispatch }) => {
    try {
      return await axios
        .post(`${endpoint}/api/users/updateProfile`, data)
        .then((res) => dispatch(setMember(res.data)))
        .catch((err) => {
          throw err.response.data;
        });
    } catch (e) {
      console.warn("Update profile thunk failed", e);
      return rejectWithValue(e);
    }
  }
);
