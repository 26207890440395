import React from "react";
import {
  View,
  Image,
  StyleSheet,
  useWindowDimensions,
  Text,
} from "react-native";
import ContactForm from "./ContactForm";

const WantToKnowMore = () => {
  const width = useWindowDimensions().width;

  return (
    <View style={{ position: "relative" }}>
      <View
        style={{
          marginVertical: 20,
          flexDirection: "row",
          zIndex: 999,
        }}
      >
        <View
          style={{
            flex: 1,
            marginHorizontal: width > 800 ? "10%" : 0,
            // height: 400,
          }}
        >
          <View
            style={{
              margin: 30,
              flex: 1,
              flexDirection: width > 800 ? "row" : "column",
            }}
          >
            <View style={{ flex: 1 }}>
              <Text
                style={{
                  fontSize: 40,
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                WANT TO KNOW MORE
              </Text>

              <Text
                style={{
                  fontSize: 15,
                  color: "white",
                  marginTop: 20,
                }}
              >
                Allsafe Labour is a leading labor hire and recruitment agency
                dedicated to empowering both individuals and companies in their
                career growth and success. Our core belief centers around the
                idea that Safety and Expertise Build Futures.
              </Text>
              <Text
                style={{
                  fontSize: 15,
                  color: "white",
                  marginTop: 20,
                }}
              >
                At Allsafe Labour, we understand that the right people are
                essential to any organization's growth and prosperity. We are
                committed to providing unparalleled opportunities and top-tier
                talent to businesses across various industries, ensuring that
                they have the expertise needed to thrive.
              </Text>
            </View>
            <View
              style={{
                flex: 1,
                marginLeft: width > 800 ? 50 : 0,
                marginTop: width < 800 ? 50 : 0,
              }}
            >
              <ContactForm />
            </View>
          </View>
        </View>
      </View>

      <Image
        source={{ uri: require("../assets/wanttoknowmore.png") }}
        style={{
          width: "100%",
          position: "absolute",
          top: 0,
          bottom: 0,
        }}
      />
      <View style={styles.overlay} />
    </View>
  );
};

export default WantToKnowMore;

const styles = StyleSheet.create({
  overlay: {
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
});
