import { createSlice, PayloadAction } from "@reduxjs/toolkit";
const initialState = null as null | string;
const slice = createSlice({
  name: "token",
  initialState,
  reducers: {
    setToken(state, action: PayloadAction<string | null>) {
      return action.payload;
    },
  },
});

export const { setToken } = slice.actions;

export default slice.reducer;
