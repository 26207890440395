import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = null as null | ModalParams;

const slice = createSlice({
  name: "currentModal",
  initialState,
  reducers: {
    setCurrentModal(state, action: PayloadAction<ModalParams | null>) {
      return action.payload;
    },
  },
});

export const { setCurrentModal } = slice.actions;

export default slice.reducer;
