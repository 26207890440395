import { StyleSheet, Text, View } from "react-native";
import React from "react";
import { useAppDispatch } from "../redux-app/hooks";
import { useNavigation } from "@react-navigation/native";
import Touchable from "./Touchable";
import { REGISTER_SCREEN } from "../screens/Register";
import {
  Formik,
  Form,
  FormikValues,
  FormikHelpers,
  FormikErrors,
} from "formik";
import TextField from "./TextField";
import FilledButton from "./FilledButton";
import themes from "../constants/themes";
import * as yup from "yup";
import { setFullPageLoading } from "../reducers/fullPageLoading";
import { login } from "../thunks/login";
import { PROFILE_SCREEN } from "../screens/Profile";
import { UNAUTHORISED } from "../navigation/UnauthorisedNavigation";
import { FORGOT_PASSWORD_SCREEN } from "../screens/ForgotPassword";

const LoginForm = () => {
  const navigation = useNavigation<UseNavigationProps>();
  const dispatch = useAppDispatch();

  const submit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    dispatch(setFullPageLoading(true));
    await dispatch(login(values))
      .unwrap()
      .then(() => navigation.navigate(PROFILE_SCREEN))
      .catch((err) => {
        actions.setErrors(err as FormikErrors<FormikValues>);
      });
  };

  const initialValues: FormikValues = {
    email: __DEV__ ? "ertemishakk@gmail.com" : "",
    password: __DEV__ ? "Forman1.," : "",
  };

  const schema = {
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Minimum 8 characters"),
  };

  const validation = yup.object().shape(schema);

  return (
    <View style={{ padding: 30, flex: 1 }}>
      <Text style={{ fontSize: 40, textAlign: "center", marginVertical: 40 }}>
        LOG IN
      </Text>
      <Formik
        validationSchema={validation}
        onSubmit={submit}
        initialValues={initialValues}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(props: any) => {
          return (
            <>
              <TextField name="email" keyboardType="email-address" autoFocus />
              <TextField name="password" secureTextEntry />
              <FilledButton onPress={props.handleSubmit} label="Log in" />
            </>
          );
        }}
      </Formik>

      <Touchable
        containerStyle={[
          {
            justifyContent: "center",
            flexDirection: "row",
          },
          styles.link,
        ]}
        onPress={() => navigation.navigate(REGISTER_SCREEN)}
      >
        <Text style={{ fontSize: 15 }}>Don't have an account? </Text>
        <Text
          style={{
            fontSize: 15,
            color: themes.secondaryColor,
            textDecorationLine: "underline",
          }}
        >
          Register now
        </Text>
      </Touchable>

      <Touchable
        containerStyle={[
          {
            alignItems: "center",
          },
          styles.link,
        ]}
        onPress={() => {
          navigation.navigate(UNAUTHORISED, { screen: FORGOT_PASSWORD_SCREEN });
        }}
      >
        <Text style={{ fontSize: 15 }}>Forgot Password</Text>
      </Touchable>
    </View>
  );
};

export default LoginForm;

const styles = StyleSheet.create({
  input: {
    paddingVertical: 10,
    fontSize: 20,
    borderBottomWidth: 1,
    borderBottomColor: "silver",
  },
  inputColorInactive: {
    color: "silver",
  },
  web: {
    //@ts-ignore
    outlineStyle: "none",
  },

  link: {
    marginTop: 20,
    paddingHorizontal: 20,
    borderRadius: 5,
  },
});
