import React from "react";
import { Text, View } from "react-native";
import { Icon } from "@rneui/themed";
import Touchable from "./Touchable";
import { useFormikContext, FormikValues } from "formik";
import themes from "../constants/themes";

const Checkbox = ({ name, text }: { name: string; text?: string }) => {
  const { values, errors, setFieldValue } = useFormikContext<FormikValues>();
  return (
    <View>
      <Touchable
        containerStyle={{
          flexDirection: "row",
          marginTop: 20,
          alignItems: "center",
        }}
        onPress={() => setFieldValue(name, !values[name])}
      >
        <Icon
          name={values[name] ? "check-box" : "check-box-outline-blank"}
          color={themes.primaryColor}
        />
        <Text style={{ marginLeft: 10, color: themes.secondaryColor }}>
          {text}
        </Text>
      </Touchable>
      {errors[name] !== "" && (
        <Text style={{ color: "red", marginTop: 10, fontSize: 12 }}>
          {errors[name] as keyof FormikValues}
        </Text>
      )}
    </View>
  );
};

export default Checkbox;
