import React, { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { Text, View } from "react-native";
import { FormikValues, useFormikContext } from "formik";

const FilePicker = ({
  name,
  setAssets,
  multiple = false,
}: {
  name: string;
  setAssets: (files: any) => void;
  multiple?: boolean;
}) => {
  const { errors } = useFormikContext<FormikValues>();
  const onDrop = useCallback((acceptedFiles: any) => {
    if (acceptedFiles) {
      const files: any = [];

      acceptedFiles.forEach((file: any) => {
        files.push(file);
      });

      setAssets([...files]);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } =
    //@ts-ignore
    useDropzone({
      onDrop,
      multiple,
      accept: {
        "image/png": [".png"],
        "application/pdf": [".pdf"],
        "image/jpeg": [".jpg", ".jpeg"],
        "application/msword": [".doc"],
        "image/webp": [".webp"],
      },
    });

  return (
    <View>
      <div
        {...getRootProps()}
        style={{
          backgroundColor: "whitesmoke",
          color: "silver",
          cursor: "pointer",
          fontSize: 12,
          borderRadius: 5,
          border: "1px solid silver",
          marginBottom: 5,
        }}
      >
        {/*     
// @ts-ignore */}
        <input {...getInputProps()} />
        <div>
          <p style={{ padding: 20, color: "grey", textAlign: "center" }}>
            {isDragActive
              ? " Drop the files here"
              : ` Drag 'n' drop, or click to select files`}
            <br />
            <span>(Accepted file types are .png, .jpg, .jpeg, .pdf, .doc)</span>
          </p>
        </div>
      </div>

      {Boolean(errors[name]) && (
        <Text style={{ color: "red", fontSize: 12, marginBottom: 10 }}>
          {errors[name] as keyof FormikValues}
        </Text>
      )}
    </View>
  );
};

export default FilePicker;
