import { combineReducers, AnyAction, Reducer } from "redux";
import lodash from "lodash";
import member from "./member";
import currentModal from "./currentModal";
import fullPageLoading from "./fullPageLoading";
import token from "./token";

const PERSIST = {
  member,
  token,
};

const rootReducer: Reducer<FrontEndState, AnyAction> = combineReducers({
  currentModal,
  fullPageLoading,
  ...PERSIST,
});

export const PERSIST_KEYS = lodash.keys(PERSIST);

export default rootReducer;
