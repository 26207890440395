import {
  Platform,
  StyleSheet,
  Text,
  TextInput,
  useWindowDimensions,
  View,
} from "react-native";
import React from "react";

export const NOT_FOUND = "NOTFOUND";

const NotFound = () => {
  const width = useWindowDimensions().width;

  return (
    <View style={{ backgroundColor: "white", flex: 1 }}>
      <Text>Not found</Text>
    </View>
  );
};

export default NotFound;

const styles = StyleSheet.create({});
