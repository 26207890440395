import { createAsyncThunk } from "@reduxjs/toolkit";
import { FormikValues } from "formik";
import axios from "axios";
import endpoint from "../constants/endpoint";
import { setToken } from "../reducers/token";
import { setMember } from "../reducers/member";

export const login = createAsyncThunk(
  "login",
  async (data: FormikValues, { dispatch, rejectWithValue }) => {
    try {
      return await axios
        .post(`${endpoint}/api/users/login`, data)
        .then((response) => {
          const { user, token } = response.data;
          dispatch(setToken(token));
          dispatch(setMember(user));
        })
        .catch((err) => {
          throw err.response.data;
        });
    } catch (e) {
      console.warn("login thunk failed", e);
      return rejectWithValue(e);
    }
  }
);
