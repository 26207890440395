import React, { useRef } from "react";
import {
  KeyboardTypeOptions,
  Platform,
  StyleSheet,
  Text,
  TextInput,
  TextStyle,
  View,
} from "react-native";
import lodash from "lodash";

import { useFormikContext, FormikValues } from "formik";
import { Icon } from "@rneui/themed";
import Touchable from "./Touchable";

const TextField = ({
  placeholder,
  name,
  keyboardType,
  secureTextEntry = false,
  autoFocus,
  autoCapitalize = "sentences",
  multiline = false,
  addExtraSpace = false,
  showCaret = false,
  onPress,
  style,
  disabled = false,
}: {
  placeholder?: string;
  name: string;
  keyboardType?: KeyboardTypeOptions;
  secureTextEntry?: boolean;
  autoFocus?: boolean;
  autoCapitalize?: "none" | "sentences";
  multiline?: boolean;
  addExtraSpace?: boolean;
  showCaret?: boolean;
  onPress?: () => void;
  style?: TextStyle;
  disabled?: boolean;
}) => {
  const { values, errors, setFieldValue } = useFormikContext<FormikValues>();
  const inputRef = useRef<TextInput | null>(null);

  return (
    <Touchable
      onPress={() => {
        if (onPress) {
          onPress();
        } else {
          inputRef.current?.focus();
        }
      }}
      containerStyle={Platform.OS === "web" && styles.web}
      disabled={disabled}
    >
      <View pointerEvents={showCaret ? "none" : "auto"}>
        <View
          style={{
            borderWidth: 1,
            borderColor: "silver",
            borderRadius: 5,
            padding: 10,
            marginVertical: 10,
            flexDirection: "row",
            backgroundColor: disabled ? "whitesmoke" : "white",
          }}
        >
          <TextInput
            ref={inputRef}
            multiline={multiline}
            placeholder={placeholder ? placeholder : lodash.startCase(name)}
            value={values[name]}
            style={[
              styles.input,
              Platform.OS === "web" ? styles.web : {},
              values[name] === "" && styles.inputColorInactive,
              addExtraSpace && styles.height,
              style,
            ]}
            onChangeText={(text) => setFieldValue(name, text)}
            keyboardType={keyboardType ? keyboardType : "default"}
            secureTextEntry={secureTextEntry}
            autoFocus={autoFocus}
            autoComplete="off"
            autoCapitalize={autoCapitalize}
            placeholderTextColor="grey"
            editable={showCaret || disabled ? false : true}
            selectTextOnFocus={disabled ? false : true}
          />
          {showCaret && <Icon name="arrow-drop-down" />}
        </View>
        {Boolean(errors[name]) && (
          <Text style={{ color: "red", fontSize: 12, marginBottom: 10 }}>
            {errors[name] as keyof FormikValues}
          </Text>
        )}
      </View>
    </Touchable>
  );
};

export default TextField;

const styles = StyleSheet.create({
  input: {
    fontSize: 16,
    flex: 1,
  },
  inputColorInactive: {
    color: "silver",
  },
  web: {
    //@ts-ignore
    outlineStyle: "none",
  },
  height: {
    minHeight: 100,
  },
});
