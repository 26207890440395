import {
  FlatList,
  Platform,
  StyleSheet,
  useWindowDimensions,
  View,
} from "react-native";
import React from "react";
import HomeHeader from "../components/HomeHeader";
import LabourHire from "../components/LabourHire";
import TraficManagement from "../components/TraficManagement";
import WantToKnowMore from "../components/WantToKnowMore";
import Footer from "../components/Footer";

export const HOME_SCREEN = "HOME";

const Home = () => {
  const width = useWindowDimensions().width;

  return (
    <View style={styles.root}>
      <FlatList
        data={[]}
        ListHeaderComponent={() => (
          <View>
            <HomeHeader />
            <LabourHire />
            <TraficManagement />
            <WantToKnowMore />
            <Footer />
          </View>
        )}
        columnWrapperStyle={{
          marginHorizontal: width > 800 ? "10%" : 0,
        }}
        numColumns={Platform.OS === "web" ? (width > 800 ? 4 : 2) : 2}
        key={Platform.OS === "web" ? (width > 800 ? 5 : 3) : 2}
        renderItem={({ item }) => <></>}
      />
    </View>
  );
};

export default Home;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: "white",
  },
});
