import { ScrollView, Text, useWindowDimensions, View } from "react-native";
import React, { useEffect, useState } from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import themes from "../constants/themes";
import { Formik, FormikHelpers, FormikValues } from "formik";
import FilledButton from "../components/FilledButton";
import TextField from "../components/TextField";
import * as yup from "yup";
import axios from "axios";
import endpoint from "../constants/endpoint";
import { useAppDispatch } from "../store";
import { setFullPageLoading } from "../reducers/fullPageLoading";
import { useNavigation, useRoute } from "@react-navigation/native";
import { LOGIN_SCREEN } from "./Login";

export const RESET_PASSWORD_SCREEN = "RESET_PASSWORD";

const ResetPassword = () => {
  const width = useWindowDimensions().width;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const dispatch = useAppDispatch();
  const route = useRoute();
  const navigation = useNavigation<UseNavigationProps>();

  useEffect(() => {
    if (showSuccessMessage) {
      setTimeout(() => {
        setShowSuccessMessage(false);
        navigation.navigate(LOGIN_SCREEN);
      }, 5000);
    }
  }, [showSuccessMessage]);

  const submit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    dispatch(setFullPageLoading(true));
    await axios
      .post(`${endpoint}/api/users/update-password`, {
        ...values,
        resetCode: (route!.params! as any).code!,
      })
      .then((response) => {
        setShowSuccessMessage(true);
        dispatch(setFullPageLoading(false));
        actions.resetForm();
      })
      .catch((err) => {
        actions.setErrors(err);
        dispatch(setFullPageLoading(false));
      });
  };
  const initialValues: FormikValues = {
    password: "",
    confirmPassword: "",
  };

  const schema = {
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Minimum 8 characters"),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .min(8, "Minimum 8 characters")
      .oneOf([yup.ref("password")], "Passwords must match"),
  };

  const validation = yup.object().shape(schema);

  return (
    <SafeAreaView
      edges={["bottom"]}
      style={{ flex: 1, backgroundColor: themes.defaultBackgroundColor }}
    >
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        <View
          style={{
            marginHorizontal: width > 800 ? "20%" : 20,
            flex: 1,
          }}
        >
          <Text
            style={{ fontSize: 40, textAlign: "center", marginVertical: 40 }}
          >
            RESET PASSWORD
          </Text>
          <Formik
            validationSchema={validation}
            onSubmit={submit}
            initialValues={initialValues}
            validateOnChange={false}
            validateOnBlur={false}
          >
            {(props: any) => {
              return (
                <>
                  <TextField name="password" secureTextEntry />
                  <TextField name="confirmPassword" secureTextEntry />

                  {showSuccessMessage && (
                    <Text style={{ color: "green" }}>
                      Your email has been reset. You're being redirected to
                      login page.
                    </Text>
                  )}

                  <FilledButton onPress={props.handleSubmit} label="Reset" />
                </>
              );
            }}
          </Formik>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default ResetPassword;
