import React from "react";
import {
  View,
  Text,
  useWindowDimensions,
  StyleSheet,
  ScrollView,
} from "react-native";
import ImageTitle from "../components/ImageTitle";
import Touchable from "../components/Touchable";
import Footer from "../components/Footer";
import ContactNowButton from "../components/ContactNowButton";

export const TRAFFIC_SCREEN = "TRAFFIC";
const Traffic = () => {
  const width = useWindowDimensions().width;
  return (
    <ScrollView style={styles.root}>
      <ImageTitle
        text="Traffic Solutions"
        subtitle="Efficient and Safe Traffic Management for Your Projects"
        uri={require("../assets/t1.jpg")}
      />
      <View
        style={{
          marginHorizontal: width > 800 ? "10%" : 0,
          marginTop: 20,
        }}
      >
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          At AllSafe Labour Hire, we understand the vital role that traffic
          management plays in ensuring the safety and efficiency of
          construction, infrastructure, and event projects. Our Traffic
          Solutions division is dedicated to providing top-notch traffic
          management services to keep your projects running smoothly and
          securely.
        </Text>
        <Text
          style={{
            marginHorizontal: 30,
            marginVertical: 10,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Why Choose AllSafe Traffic Solutions?
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Expertise: </Text>
          Our team of experienced traffic management professionals brings a
          wealth of knowledge to the table. We understand the complexities of
          traffic control and are well-versed in industry regulations and best
          practices.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Safety First: </Text>
          Safety is paramount when it comes to managing traffic. Our personnel
          are rigorously trained and certified to ensure that all aspects of
          traffic management are executed with the highest safety standards in
          mind.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Customized Solutions: </Text>
          We recognize that each project is unique. Our traffic solutions are
          tailored to your specific needs, whether you're dealing with road
          construction, special events, or any situation requiring controlled
          traffic flow.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>
            State-of-the-Art Equipment:{" "}
          </Text>
          We provide cutting-edge traffic management equipment and tools to
          optimize traffic flow and ensure the safety of both workers and the
          public.
        </Text>

        <Text
          style={{
            marginHorizontal: 30,
            marginVertical: 10,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Our Traffic Management Services
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Traffic Control Plans: </Text>
          We create comprehensive traffic control plans that adhere to local
          regulations and project requirements. These plans ensure the safe
          movement of vehicles and pedestrians in and around work zones.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Flagging Services: </Text>
          Our trained flaggers are skilled in directing traffic safely and
          efficiently. They play a crucial role in maintaining order and
          minimizing disruptions.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>
            Temporary Traffic Signs and Devices:{" "}
          </Text>
          We supply and install temporary traffic signs, cones, barriers, and
          other devices to clearly communicate road changes and hazards to
          drivers.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>
            Traffic Management for Events:{" "}
          </Text>
          Whether it's a festival, parade, or sporting event, we provide traffic
          management solutions to facilitate the smooth flow of vehicles and
          pedestrians.
        </Text>

        <Text
          style={{
            marginHorizontal: 30,
            marginVertical: 10,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Contact Us
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          Ready to ensure efficient and safe traffic management for your
          project? Contact AllSafe Labour today to discuss your traffic
          management needs, and let us help you create a secure and
          well-organized traffic environment.
        </Text>
      </View>
      <ContactNowButton />
      <Footer />
    </ScrollView>
  );
};

export default Traffic;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: "white",
  },
});
