import React from "react";
import {
  View,
  Text,
  Modal,
  useWindowDimensions,
  ScrollView,
} from "react-native";
import { Icon } from "@rneui/themed";
import Touchable from "../components/Touchable";
import { useAppDispatch } from "../redux-app/hooks";
import { setCurrentModal } from "../reducers/currentModal";
import useHeaderMenu from "../hooks/useHeaderMenu";

export const NAVIGATION_MODAL = "NAVIGATION_MODAL";

const NavigationModal = () => {
  const width = useWindowDimensions().width;
  const dispatch = useAppDispatch();
  const headerMenu = useHeaderMenu();

  return (
    <View>
      <Modal animationType="slide" visible={width < 800 ? true : false}>
        <Touchable
          containerStyle={{
            alignItems: "flex-end",
            borderColor: "silver",
            padding: 10,
          }}
          onPress={() => dispatch(setCurrentModal(null))}
        >
          <Icon name="close" size={25} />
        </Touchable>
        <ScrollView
          contentContainerStyle={{
            flex: 1,
            rowGap: 20,
            alignItems: "center",
            marginTop: 20,
          }}
        >
          {headerMenu.map((category) => (
            <Touchable
              key={category.text}
              onPress={() => {
                if (category.onPress) {
                  category.onPress();
                  dispatch(setCurrentModal(null));
                }
              }}
            >
              <View style={{ paddingHorizontal: 20 }}>
                <Text
                  style={{
                    fontSize: 15,
                    fontWeight: "bold",
                    textTransform: "uppercase",
                  }}
                >
                  {category.text}
                </Text>
              </View>
            </Touchable>
          ))}
        </ScrollView>
      </Modal>
    </View>
  );
};

export default NavigationModal;
