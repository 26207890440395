import { Text, View, useWindowDimensions, Image } from "react-native";
import React, { useEffect, useState } from "react";

const LabourHire = () => {
  const width = useWindowDimensions().width;
  const [size, setSize] = useState(0);

  return (
    <View style={{ marginHorizontal: width > 800 ? "10%" : 0 }}>
      <Text style={{ fontSize: 30, fontWeight: "bold", margin: 30 }}>
        AllSafe Labour Hire
      </Text>
      <Text style={{ marginHorizontal: 30, marginVertical: 10 }}>
        Welcome to Allsafe Labour
      </Text>

      <Text style={{ marginHorizontal: 30, marginVertical: 10 }}>
        Your premier destination for exceptional labor hire services. With a
        steadfast commitment to excellence, we specialize in providing
        top-quality, skilled, and reliable workforce solutions to various
        industries, including Construction, Building, Civil & Rail, Security
        Services, and Traffic Management. Whether you require temporary or
        long-term personnel, we have you covered with our flexible and tailored
        staffing options.
      </Text>

      <Text style={{ marginHorizontal: 30, marginVertical: 10 }}>
        Backed by a team of dedicated professionals, we take pride in ensuring
        your projects are equipped with the best-suited personnel, ensuring
        efficiency, safety, and success at every step. Trust Allsafe Labour for
        all your labor hire needs and experience a seamless and productive
        partnership like never before. Your success is our priority!
      </Text>
      <View
        style={{ flex: 1, flexDirection: "row", margin: 30 }}
        onLayout={(e) => setSize(e.nativeEvent.layout.width)}
      >
        <Image
          source={{
            uri: require("../assets/hire-1.jpg"),
            height: 400,
            width: size / 2,
          }}
          //   style={{ width: "100%" }}
        />
        <Image
          source={{
            uri: require("../assets/hire-2.jpeg"),
            height: 400,
            width: size / 2,
          }}
          style={{ marginLeft: 20 }}
        />
      </View>
    </View>
  );
};

export default LabourHire;
