import React, { useEffect } from "react";
import {
  LinkingOptions,
  NavigationContainer,
  PathConfigMap,
  useNavigationContainerRef,
} from "@react-navigation/native";
import { useAppDispatch, useAppSelector } from "../redux-app/hooks";
import { sync } from "../thunks/sync";
import axios from "axios";

const Container = ({ children }: { children: React.ReactNode }) => {
  const dispatch = useAppDispatch();
  const navigationRef = useNavigationContainerRef();
  const member = useAppSelector((state) => state.member);
  const token = useAppSelector((state) => state.token);

  useEffect(() => {
    if (token) {
      axios.defaults.headers.common["Authorization"] = token;
    } else {
      delete axios.defaults.headers.common["Authorization"];
    }
  }, [token]);

  useEffect(() => {
    dispatch(sync());
  }, []);

  const defaultLinks: PathConfigMap<RootNav> = {
    HOME: {
      path: "/home",
    },
    ABOUT: {
      path: "/about",
    },
    LABOUR: {
      path: "/labour-hire-services",
    },
    TRAFFIC: {
      path: "/traffic-solutions",
    },
    CONTACT: {
      path: "/contact",
    },
    PROFILE: {
      path: "/profile",
    },
    REGISTER: {
      path: "/register",
    },
  };

  const authorisedLinks: PathConfigMap<RootNav> = {
    EDIT_PROFILE: {
      path: "/edit-profile",
    },
  };

  const unAuthorisedLinks: PathConfigMap<RootNav> = {
    UNAUTHORISED: {
      screens: {
        LOGIN: {
          path: "/login",
        },
        FORGOT_PASSWORD: {
          path: "/forgot-password",
        },
        RESET_PASSWORD: {
          path: "/resetPassword",
        },
      },
    },
  };

  const memberLinks = member ? authorisedLinks : unAuthorisedLinks;

  const linking: LinkingOptions<RootNav> = {
    prefixes: [],
    config: {
      screens: {
        ...defaultLinks,
        ...memberLinks,
        NOTFOUND: "*",
      },
    },
  };

  return (
    <NavigationContainer
      linking={linking}
      ref={navigationRef}
      documentTitle={{
        formatter: (options, route) => {
          return `${options?.title ?? route?.name} - Allsafe Labour`;
        },
      }}
    >
      {children}
    </NavigationContainer>
  );
};

export default Container;
