import React from "react";
import { Text } from "react-native";
import themes from "../constants/themes";
import Touchable from "./Touchable";

const FilledButton = ({
  label,
  onPress,
}: {
  label: string;
  onPress?: () => void;
}) => {
  return (
    <Touchable
      onPress={onPress}
      containerStyle={{
        marginTop: 20,
        backgroundColor: themes.primaryColor,
        padding: 10,
        borderRadius: 5,
        alignItems: "center",
      }}
    >
      <Text style={{ color: "white", fontSize: 18 }}>{label}</Text>
    </Touchable>
  );
};

export default FilledButton;
