import {
  ScrollView,
  StyleSheet,
  Text,
  useWindowDimensions,
  View,
} from "react-native";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { useAppDispatch, useAppSelector } from "../redux-app/hooks";
import { useNavigation } from "@react-navigation/native";
import Touchable from "../components/Touchable";
import { Formik, FormikHelpers, FormikValues } from "formik";
import TextField from "../components/TextField";
import { LOGIN_SCREEN } from "./Login";
import Checkbox from "../components/CheckBox";
import FilledButton from "../components/FilledButton";
import themes from "../constants/themes";
import * as yup from "yup";
import { register } from "../thunks/register";
import { setFullPageLoading } from "../reducers/fullPageLoading";
import FilePicker from "../components/FilePicker";
import { Icon } from "@rneui/themed";
import axios from "axios";
import endpoint from "../constants/endpoint";
import { setMember } from "../reducers/member";
import { PROFILE_SCREEN } from "./Profile";

export const REGISTER_SCREEN = "REGISTER";

const Register = () => {
  const dispatch = useAppDispatch();
  const navigation = useNavigation<UseNavigationProps>();
  const width = useWindowDimensions().width;

  const submit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    dispatch(setFullPageLoading(true));

    let formData = new FormData();

    formData.append("resume", values.resume[0]);

    if (values.coverLetter.length > 0) {
      formData.append("coverLetter", values.coverLetter[0]);
    }

    if (values.tickets.length > 0) {
      values.tickets.map((ticket: string | Blob) => {
        formData.append("ticket", ticket);
      });
    }

    axios
      .post(`${endpoint}/api/files/upload`, formData)
      .then(async (response) => {
        const { data } = response;

        await dispatch(register({ ...values, ...data }))
          .unwrap()
          .then((res) => {
            // set user
            // navigate to profile
            dispatch(setMember(res.data));
            dispatch(setFullPageLoading(false));
            navigation.navigate(PROFILE_SCREEN);
          })
          .catch((e) => {
            console.log(e);
            actions.setErrors(e);
            dispatch(setFullPageLoading(false));
          });
      })
      .catch((e) => {
        actions.setErrors(e.response.data);
        dispatch(setFullPageLoading(false));
      });
  };

  const initialValues: FormikValues = {
    email: "",
    confirmEmail: "",
    password: "",
    confirmPassword: "",
    phoneNumber: "",
    firstName: "",
    lastName: "",
    address: "",
    terms: true,
    tickets: [],
    resume: [],
    coverLetter: [],
    city: "",
    state: "",
    postcode: "",
  };

  const schema = {
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .min(8, "too short")
      .max(12, "too long"),
    email: yup.string().email("Invalid email").required("Email is required"),
    confirmEmail: yup
      .string()
      .required("Confirm email is required")
      .email("Invalid email")
      .oneOf([yup.ref("email")], "Emails must match"),
    password: yup
      .string()
      .required("Password is required")
      .min(8, "Minimum 8 characters"),
    confirmPassword: yup
      .string()
      .required("Confirm password is required")
      .min(8, "Minimum 8 characters")
      .oneOf([yup.ref("password")], "Passwords must match"),
    terms: yup.bool().oneOf([true], "Required").required(),
    address: yup.string().required("Address is required"),
    resume: yup.array().min(1, "Select at least 1 file"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    postcode: yup.string().required("Postcode is required"),
  };

  const validation = yup.object().shape(schema);

  return (
    <SafeAreaView
      edges={["bottom"]}
      style={{ flex: 1, backgroundColor: "white" }}
    >
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{ paddingBottom: 50 }}
        keyboardDismissMode="on-drag"
      >
        <Text style={{ fontSize: 40, textAlign: "center", marginVertical: 40 }}>
          APPLICATION FORM
        </Text>
        <View
          style={{
            marginHorizontal: width > 800 ? "10%" : 0,
            flex: 1,
            padding: width > 800 ? 30 : 0,
            borderRadius: 10,
            backgroundColor: width > 800 ? "whitesmoke" : "white",
          }}
        >
          <View style={{ paddingHorizontal: 30, flex: 1 }}>
            <Formik
              validationSchema={validation}
              onSubmit={submit}
              initialValues={initialValues}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({ setFieldValue, handleSubmit, values }: any) => (
                <View>
                  <View
                    style={{ flexDirection: "row", flex: 1, columnGap: 20 }}
                  >
                    <View style={{ flex: 1 }}>
                      <TextField
                        name="email"
                        keyboardType="email-address"
                        autoFocus
                        autoCapitalize="none"
                      />
                      <TextField name="password" secureTextEntry />

                      <TextField name="firstName" placeholder="First name" />
                      <TextField name="address" />
                      <TextField name="state" />
                      <TextField
                        name="phoneNumber"
                        keyboardType="phone-pad"
                        placeholder="Phone number"
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <TextField
                        name="confirmEmail"
                        keyboardType="email-address"
                        autoFocus
                        autoCapitalize="none"
                        placeholder="Confirm email"
                      />
                      <TextField
                        name="confirmPassword"
                        secureTextEntry
                        placeholder="Confirm password"
                      />
                      <TextField name="lastName" placeholder="Last name" />
                      <TextField name="city" />
                      <TextField name="postcode" />
                      <TextField
                        name="feedback"
                        placeholder="How did you hear about Allsafe Labour?"
                      />
                    </View>
                  </View>

                  <View
                    style={{
                      flexDirection: "row",
                      columnGap: 20,
                      marginTop: 20,
                    }}
                  >
                    <View style={{ flex: 1 }}>
                      <Text style={{ marginBottom: 10 }}>Upload Resume</Text>
                      <View
                        style={{
                          marginBottom: 10,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Icon name="attach-file" size={24} />
                        <Text>
                          {values.resume.length > 0
                            ? "1 selected"
                            : "No file selected"}
                        </Text>
                      </View>

                      <FilePicker
                        name="resume"
                        setAssets={(files) => {
                          setFieldValue("resume", files);
                        }}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text style={{ marginBottom: 10 }}>
                        Upload Cover Letter
                      </Text>
                      <View
                        style={{
                          marginBottom: 10,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Icon name="attach-file" size={24} />
                        <Text>
                          {values.coverLetter.length
                            ? "1 selected"
                            : "No file selected"}
                        </Text>
                      </View>
                      <FilePicker
                        name="coverLetter"
                        setAssets={(files) => {
                          setFieldValue("coverLetter", files);
                        }}
                      />
                    </View>
                    <View style={{ flex: 1 }}>
                      <Text style={{ marginBottom: 10 }}>
                        Other( Tickets etc.)
                      </Text>
                      <View
                        style={{
                          marginBottom: 10,
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Icon name="attach-file" size={24} />
                        <Text>
                          {values.tickets.length > 0
                            ? `${(values.tickets as any).length} selected`
                            : "No file selected"}
                        </Text>
                      </View>
                      <FilePicker
                        name="tickets"
                        multiple
                        setAssets={(files) => {
                          setFieldValue("tickets", files);
                        }}
                      />
                    </View>
                  </View>

                  <Checkbox
                    name="terms"
                    text={`By registering, I agree to Allsafe Labour's Terms of Use and Privacy Policy and I consent to receiving marketing communications from Allsafe Labour.`}
                  />
                  <FilledButton onPress={handleSubmit} label="Register" />
                </View>
              )}
            </Formik>
            <Touchable
              containerStyle={{
                marginTop: 20,
                paddingHorizontal: 20,
                borderRadius: 5,
                justifyContent: "center",
                flexDirection: "row",
              }}
              onPress={() => navigation.navigate(LOGIN_SCREEN)}
            >
              <Text style={{ fontSize: 15, color: themes.secondaryColor }}>
                Already registered with Allsafe Labour?{" "}
              </Text>
              <Text
                style={{
                  fontSize: 15,
                  color: themes.primaryColor,
                  textDecorationLine: "underline",
                }}
              >
                Log in
              </Text>
            </Touchable>
          </View>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default Register;

const styles = StyleSheet.create({
  input: {
    paddingVertical: 10,
    fontSize: 20,
    borderBottomWidth: 1,
    borderBottomColor: "silver",
  },
  inputColorInactive: {
    color: "silver",
  },
  web: {
    //@ts-ignore
    outlineStyle: "none",
  },
});
