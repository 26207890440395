import { FlatList, Text, View, useWindowDimensions } from "react-native";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { useAppDispatch, useAppSelector } from "../redux-app/hooks";
import Touchable from "../components/Touchable";
import lodash from "lodash";
import themes from "../constants/themes";
import { Icon } from "@rneui/themed";
import { logout } from "../thunks/logout";
import { useNavigation } from "@react-navigation/native";
import { HOME_SCREEN } from "./Home";
import { UNAUTHORISED } from "../navigation/UnauthorisedNavigation";
import { MEMBER_PROFILE } from "./MemberProfile";
import ListItemWithIcon from "../components/ListItemWithIcon";
import { EDIT_PROFILE_SCREEN } from "./EditProfile";

export const PROFILE_SCREEN = "PROFILE";

const Profile = () => {
  const member = useAppSelector((state) => state.member);
  const dispatch = useAppDispatch();
  const navigation = useNavigation<UseNavigationProps>();

  const menu = [
    {
      icon: "person",
      name: "Personal information",
      action: () => navigation.navigate(EDIT_PROFILE_SCREEN),
    },

    {
      icon: "logout",
      name: "Log out",
      action: () => {
        dispatch(logout());
        navigation.navigate(HOME_SCREEN);
        navigation.navigate(UNAUTHORISED);
      },
    },
  ];
  const width = useWindowDimensions().width;

  return (
    <SafeAreaView
      edges={["top"]}
      style={{ backgroundColor: themes.defaultBackgroundColor, flex: 1 }}
    >
      <View style={{ flex: 1, marginHorizontal: width > 800 ? "10%" : 20 }}>
        <FlatList
          data={menu}
          contentContainerStyle={{}}
          ListHeaderComponent={() => (
            <View style={{ margin: 20 }}>
              <View
                style={{
                  borderBottomWidth: 0.5,
                  borderColor: "silver",
                }}
              >
                <Text style={{ fontSize: 30, marginBottom: 20 }}>Profile</Text>

                <Touchable
                  containerStyle={{
                    marginVertical: 10,
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                  onPress={() => navigation.navigate(EDIT_PROFILE_SCREEN)}
                >
                  <Icon name="account-circle" size={60} color="silver" />
                  <View style={{ flex: 1, marginLeft: 20 }}>
                    <Text style={{ fontWeight: "400", fontSize: 17 }}>
                      {lodash.startCase(member?.firstName)}
                    </Text>
                    <Text style={{ color: "grey", fontSize: 13, marginTop: 2 }}>
                      Show profile
                    </Text>
                  </View>
                  <Icon name="chevron-right" />
                </Touchable>
              </View>
              <Text style={{ marginTop: 40, fontWeight: "400", fontSize: 20 }}>
                Settings
              </Text>
            </View>
          )}
          keyExtractor={(item, index) => `${index}`}
          renderItem={({ item }) => (
            <ListItemWithIcon
              onPress={item.action}
              icon={item.icon}
              text={item.name}
            />
          )}
        />
      </View>
    </SafeAreaView>
  );
};

export default Profile;
