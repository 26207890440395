import { createAsyncThunk } from "@reduxjs/toolkit";

export const sync = createAsyncThunk(
  "sync",
  async (data: undefined, { rejectWithValue, dispatch }) => {
    try {
    } catch (e) {
      console.warn("Sync thunk failed", e);
      return rejectWithValue(e);
    }
  }
);
