import { ScrollView, useWindowDimensions, View } from "react-native";
import React from "react";
import { SafeAreaView } from "react-native-safe-area-context";
import { useAppSelector } from "../redux-app/hooks";
import { useNavigation } from "@react-navigation/native";
import LoginForm from "../components/LoginForm";
import themes from "../constants/themes";

export const LOGIN_SCREEN = "LOGIN";

const Login = () => {
  const navigation = useNavigation<UseNavigationProps>();
  const member = useAppSelector((state) => state.member);
  const width = useWindowDimensions().width;

  return (
    <SafeAreaView
      edges={["bottom"]}
      style={{ flex: 1, backgroundColor: themes.defaultBackgroundColor }}
    >
      <ScrollView contentContainerStyle={{ flex: 1 }}>
        <View
          style={{
            marginHorizontal: width > 800 ? "20%" : 0,
            flex: 1,
          }}
        >
          <LoginForm />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default Login;
