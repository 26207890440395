import React from "react";
import { useAppSelector } from "../redux-app/hooks";
import NavigationModal, { NAVIGATION_MODAL } from "./NavigationModal";

interface ModalsType {
  [key: string]: React.ElementType;
}

const MODALS: ModalsType = {
  [NAVIGATION_MODAL]: NavigationModal,
};

const Modals = () => {
  const currentModal = useAppSelector((state) => state.currentModal)?.modal;

  const Component = MODALS[currentModal!];

  return currentModal ? <Component /> : null;
};

export default Modals;
