import React from "react";
import { ActivityIndicator, View, StyleSheet } from "react-native";
import themes from "../constants/themes";
import { useAppSelector } from "../redux-app/hooks";

const ActivityIndicatorContainer = ({ active }: { active?: boolean }) => {
  const loading = useAppSelector((state) => state.fullPageLoading) || active;
  return loading ? (
    <View style={[styles.activityIndicatorContainer]}>
      <ActivityIndicator size="large" color={themes.primaryColor} />
    </View>
  ) : (
    <></>
  );
};

export default ActivityIndicatorContainer;

const styles = StyleSheet.create({
  activityIndicatorContainer: {
    zIndex: 9999,
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,0.3)",
  },
});
