import { Text, View, useWindowDimensions, Image } from "react-native";
import React, { useEffect, useState } from "react";

const TraficManagement = () => {
  const width = useWindowDimensions().width;
  const [size, setSize] = useState(0);

  return (
    <View style={{ marginHorizontal: width > 800 ? "10%" : 0 }}>
      <Text style={{ fontSize: 30, fontWeight: "bold", margin: 30 }}>
        Traffic Management
      </Text>

      <Text style={{ marginHorizontal: 30, marginVertical: 10 }}>
        The ultimate destination for comprehensive traffic management services.
        With a proven track record of excellence, we specialize in delivering
        top-notch solutions for traffic control and safety management. Our
        highly trained and experienced personnel ensure smooth traffic flow,
        optimized road safety, and efficient work zone management. From planning
        and implementation to monitoring and control, we take care of every
        aspect with utmost precision and dedication.
      </Text>

      <Text style={{ marginHorizontal: 30, marginVertical: 10 }}>
        With Allsafe Labour by your side, you can rest assured that your traffic
        management needs are in the hands of experts. Drive your projects
        forward with confidence and reliability, as we prioritize safety,
        efficiency, and customer satisfaction above all else. Partner with us
        today and experience the difference that our commitment to excellence
        can make.
      </Text>
      <View
        style={{ flex: 1, flexDirection: "row", margin: 30 }}
        onLayout={(e) => setSize(e.nativeEvent.layout.width)}
      >
        <Image
          source={{
            uri: require("../assets/controller-1.webp"),
            height: 400,
            width: size / 2,
          }}
          //   style={{ width: "100%" }}
        />
        <Image
          source={{
            uri: require("../assets/controller-2.webp"),
            height: 400,
            width: size / 2,
          }}
          style={{ marginLeft: 20 }}
        />
      </View>
    </View>
  );
};

export default TraficManagement;
