import React from "react";
import {
  View,
  Text,
  StyleSheet,
  Platform,
  ScrollView,
  useWindowDimensions,
  Image,
} from "react-native";
import Touchable from "./Touchable";
import { Icon } from "@rneui/themed";
import LoginButton from "./LoginButton";
import useHeaderMenu from "../hooks/useHeaderMenu";
import { useNavigation } from "@react-navigation/native";
import { HOME_SCREEN } from "../screens/Home";
import { setCurrentModal } from "../reducers/currentModal";
import { NAVIGATION_MODAL } from "../Modals/NavigationModal";
import { useAppDispatch } from "../store";

const WebHeaderLinks = () => {
  const width = useWindowDimensions().width;
  const navigation = useNavigation<UseNavigationProps>();
  const headerMenu = useHeaderMenu();
  const dispatch = useAppDispatch();
  return Platform.OS === "web" ? (
    <View
      style={{
        paddingVertical: 10,
        paddingHorizontal: 5,
        backgroundColor: "white",
      }}
    >
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
          marginHorizontal: width > 800 ? "10%" : 0,
          justifyContent: "space-between",
        }}
      >
        {width < 800 && (
          <Touchable
            containerStyle={{
              alignItems: "center",
              borderWidth: 0.5,
              borderColor: "silver",
              borderRadius: 5,
              padding: 5,
            }}
            onPress={() =>
              dispatch(setCurrentModal({ modal: NAVIGATION_MODAL }))
            }
          >
            <Icon name="menu" size={25} />
          </Touchable>
        )}
        <Touchable
          containerStyle={{
            paddingRight: 10,
          }}
          onPress={() => navigation.navigate(HOME_SCREEN)}
        >
          <Image
            source={{
              uri: require("../assets/logo.png"),
              height: 40,
              width: 180,
            }}
          />
        </Touchable>
        {width > 800 && (
          <ScrollView
            horizontal={true}
            showsHorizontalScrollIndicator={false}
            contentContainerStyle={{
              flex: 1,
              flexDirection: "row",
            }}
          >
            {headerMenu.map((category) => (
              <Touchable
                key={category.text}
                onPress={() => {
                  if (category.onPress) {
                    category.onPress();
                  }
                }}
              >
                <View style={{ paddingHorizontal: 20 }}>
                  <Text
                    style={{
                      fontSize: 15,
                      fontWeight: "bold",
                    }}
                  >
                    {category.text}
                  </Text>
                </View>
              </Touchable>
            ))}
          </ScrollView>
        )}

        <LoginButton />
      </View>
    </View>
  ) : null;
};

export default WebHeaderLinks;

const styles = StyleSheet.create({});
