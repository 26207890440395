import { Icon } from "@rneui/themed";
import React from "react";
import { Text, View } from "react-native";
import Touchable from "./Touchable";
import themes from "../constants/themes";

const ListItemWithIcon = ({
  onPress,
  icon,
  text,
}: {
  onPress?: () => void;
  icon?: string;
  text: string;
}) => {
  return (
    <Touchable
      containerStyle={{
        marginVertical: 10,
        flexDirection: "row",
        alignItems: "center",
        borderBottomWidth: 1,
        marginHorizontal: 20,
        borderColor: "silver",
        paddingBottom: 10,
      }}
      onPress={onPress}
    >
      <Icon name={icon} />
      <View style={{ flex: 1, marginLeft: 20 }}>
        <Text
          style={{
            color: themes.secondaryColor,
            fontSize: 16,
            marginTop: 2,
          }}
        >
          {text}
        </Text>
      </View>
      <Icon name="chevron-right" />
    </Touchable>
  );
};

export default ListItemWithIcon;
