import React from "react";
import { createStackNavigator } from "@react-navigation/stack";
import themes from "../constants/themes";
import { Icon } from "@rneui/themed";
import Touchable from "../components/Touchable";
import { useWindowDimensions } from "react-native";

const DefaultNavigation = ({ screens }: { screens: ScreenNavigation[] }) => {
  const Stack = createStackNavigator<RootNav>();
  const width = useWindowDimensions().width;
  return (
    <Stack.Navigator>
      {screens.map(
        (screen) =>
          screen.component && (
            <Stack.Screen
              key={screen.name}
              name={screen.name}
              component={screen.component}
              options={({ navigation, route }) => {
                return {
                  headerBackImage: () => (
                    <Touchable
                      containerStyle={{
                        backgroundColor: "white",
                        borderRadius: 30,
                        padding: 5,
                        marginLeft: width > 800 ? (width * 10) / 100 : 10,
                      }}
                      onPress={() => navigation.goBack()}
                    >
                      <Icon name="arrow-back" size={20} color="black" />
                    </Touchable>
                  ),
                  headerTitleAlign: "center",
                  headerBackTitle: " ",
                  headerTitleStyle: {
                    color: themes.secondaryColor,
                  },
                  ...screen.options,
                };
              }}
            />
          )
      )}
    </Stack.Navigator>
  );
};

export default DefaultNavigation;
