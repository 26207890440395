import React from "react";
import {
  View,
  Image,
  StyleSheet,
  useWindowDimensions,
  Text,
} from "react-native";

const ImageTitle = ({
  text,
  subtitle,
  uri,
}: {
  text: string;
  uri: string;
  subtitle?: string;
}) => {
  const width = useWindowDimensions().width;

  return (
    <View style={{ position: "relative" }}>
      <View
        style={{
          zIndex: 999,
        }}
      >
        <View
          style={{
            flex: 1,
            marginHorizontal: width > 800 ? "10%" : 0,
          }}
        >
          <View
            style={{
              margin: 30,
              height: 300,
              justifyContent: "center",
            }}
          >
            <Text
              style={{
                fontSize: 40,
                fontWeight: "bold",
                color: "white",
                textAlign: "center",
              }}
            >
              {text}
            </Text>
            {subtitle && (
              <Text
                style={{
                  fontSize: 14,
                  color: "white",
                  textAlign: "center",
                  marginTop: 10,
                  fontStyle: "italic",
                }}
              >
                {subtitle}
              </Text>
            )}
          </View>
        </View>
      </View>

      <Image
        source={{ uri }}
        style={{
          width: "100%",
          position: "absolute",
          top: 0,
          bottom: 0,
        }}
      />
      <View style={styles.overlay} />
    </View>
  );
};

export default ImageTitle;

const styles = StyleSheet.create({
  overlay: {
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
});
