import React from "react";
import {
  View,
  Text,
  useWindowDimensions,
  StyleSheet,
  ScrollView,
} from "react-native";
import ImageTitle from "../components/ImageTitle";
import Touchable from "../components/Touchable";
import Footer from "../components/Footer";

export const ABOUT_SCREEN = "ABOUT";
const About = () => {
  const width = useWindowDimensions().width;
  return (
    <ScrollView style={styles.root}>
      <ImageTitle text="Who We Are" uri={require("../assets/helmet.jpg")} />
      <View
        style={{
          marginHorizontal: width > 800 ? "10%" : 0,
          marginTop: 20,
        }}
      >
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          At AllSafe Labour Hire, we are more than just a labor hire agency. We
          are your trusted partner in building success. With a steadfast
          commitment to excellence, we specialize in providing top-quality,
          skilled, and reliable workforce solutions to a wide range of
          industries. Whether your needs are temporary or long-term, we have the
          expertise and flexibility to meet them.
        </Text>
        <Text
          style={{
            marginHorizontal: 30,
            marginVertical: 10,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Our Specializations
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          Our expertise spans across multiple industries, including:
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Construction: </Text>
          We provide the skilled labor needed to bring your construction
          projects to life, ensuring that every task is carried out efficiently
          and safely.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Building: </Text>
          From residential to commercial projects, we offer tailored staffing
          solutions to meet the unique demands of the building industry.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Civil & Rail: </Text>
          Infrastructure projects require specialized skills and experience. We
          deliver a workforce that's ready to meet the challenges of civil and
          rail projects head-on.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Security Services: </Text>
          Safety and security are paramount. Our team is trained to provide
          top-notch security personnel, ensuring the protection of your assets
          and people.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Traffic Management: </Text>
          Managing traffic flow is critical for any project. Our dedicated staff
          ensures that your projects adhere to the highest safety standards.
        </Text>
        <Text
          style={{
            marginHorizontal: 30,
            marginVertical: 10,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Our Team
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          Backed by a team of dedicated professionals, we take immense pride in
          ensuring that your projects are equipped with the best-suited
          personnel. Our rigorous selection process, ongoing training, and
          commitment to safety mean that our workforce is second to none.
        </Text>
        <Text
          style={{
            marginHorizontal: 30,
            marginVertical: 10,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Your Success Is Our Priority
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          At AllSafe Labour Hire, your success is our driving force. We
          understand the critical role that a skilled and reliable workforce
          plays in the success of your projects. That's why we go the extra mile
          to match you with the right personnel, ensuring efficiency, safety,
          and success at every step.
        </Text>
        <Text
          style={{
            marginHorizontal: 30,
            marginVertical: 10,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Experience the AllSafe Difference
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          When you partner with AllSafe Labour Hire, you're not just hiring
          workers; you're gaining a seamless and productive partnership. We are
          dedicated to exceeding your expectations and helping you achieve your
          project goals. Thank you for considering AllSafe Labour Hire as your
          labor hire solution. We look forward to the opportunity to work with
          you and contribute to your success.
        </Text>
        <Text
          style={{
            marginHorizontal: 30,
            marginVertical: 10,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Contact Us
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          Ready to get started? Contact us today to discuss your labor hire
          needs, and let's embark on a journey to excellence together. Your
          Premier Destination for Exceptional Labor Hire Services
        </Text>
      </View>
      <View
        style={{
          backgroundColor: "rgb(244,147,18)",
          padding: 30,
          marginTop: 40,
        }}
      >
        <Touchable
          containerStyle={{
            justifyContent: "center",
            flex: 1,
            alignItems: "center",
          }}
        >
          <Text
            style={{
              backgroundColor: "white",
              color: "black",
              paddingHorizontal: 20,
              paddingVertical: 10,
              borderRadius: 10,
              fontWeight: "bold",
            }}
          >
            Contact Now
          </Text>
        </Touchable>
      </View>
      <Footer />
    </ScrollView>
  );
};

export default About;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: "white",
  },
});
