import React from "react";
import { Text, View } from "react-native";

const ContactDetails = () => {
  return (
    <View style={{ flex: 1 }}>
      <View style={{ rowGap: 10 }}>
        <Text style={{ fontSize: 16 }}>Our Address:</Text>
        <Text style={{ fontWeight: "bold", fontSize: 16 }}>Level 2/1</Text>
        <Text style={{ fontWeight: "bold", fontSize: 16 }}>Southbank Blvd</Text>
        <Text style={{ fontWeight: "bold", fontSize: 16 }}>
          Southbank Victoria 3006
        </Text>
      </View>
      <View style={{ rowGap: 10, marginTop: 10 }}>
        <Text style={{ fontSize: 16 }}>Phone Number:</Text>
        <Text style={{ fontWeight: "bold", fontSize: 16 }}>+61 3 99824408</Text>
      </View>
      <View style={{ rowGap: 10, marginTop: 10 }}>
        <Text style={{ fontSize: 16 }}>Email:</Text>
        <Text style={{ fontWeight: "bold", fontSize: 16 }}>
          admin@allsafelabour.com.au
        </Text>
      </View>
      <View style={{ rowGap: 10, marginTop: 10 }}>
        <Text style={{ fontSize: 16 }}>Work Hours:</Text>
        <Text style={{ fontWeight: "bold", fontSize: 16 }}>
          Monday - Friday
        </Text>
        <Text style={{ fontWeight: "bold", fontSize: 16 }}>
          8.00 am to 5.00 pm
        </Text>
      </View>
    </View>
  );
};

export default ContactDetails;
