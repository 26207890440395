import React from "react";
import DefaultNavigation from "./DefaultNavigation";
import Login, { LOGIN_SCREEN } from "../screens/Login";
import { Platform } from "react-native";
import ForgotPassword, {
  FORGOT_PASSWORD_SCREEN,
} from "../screens/ForgotPassword";
import ResetPassword, { RESET_PASSWORD_SCREEN } from "../screens/ResetPassword";

export const UNAUTHORISED = "UNAUTHORISED";

const UnauthorisedNavigation = () => {
  const screens: ScreenNavigation[] = [
    {
      name: LOGIN_SCREEN,
      component: Login,
      options: {
        headerLeft: () => null,
        headerShown: Platform.OS === "web" ? false : true,
        headerTitle: "Log in or sign up",
        headerBackImage: () => null,
        title: "Login",
      },
    },
    {
      name: FORGOT_PASSWORD_SCREEN,
      component: ForgotPassword,
      options: {
        headerTitle: "",
        headerShadowVisible: false, // applied here
        title: "Forgot Password",
      },
    },
    {
      name: RESET_PASSWORD_SCREEN,
      component: ResetPassword,
      options: {
        headerShown: false,
        title: "Reset Password",
      },
    },
  ];
  return <DefaultNavigation screens={screens} />;
};

export default UnauthorisedNavigation;
