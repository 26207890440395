import React from "react";
import { Text, View } from "react-native";
import Touchable from "./Touchable";
import { CONTACT_SCREEN } from "../screens/Contact";
import { useNavigation } from "@react-navigation/native";

const ContactNowButton = () => {
  const navigation = useNavigation<UseNavigationProps>();
  return (
    <View
      style={{
        backgroundColor: "rgb(244,147,18)",
        padding: 30,
        marginTop: 40,
      }}
    >
      <Touchable
        containerStyle={{
          justifyContent: "center",
          flex: 1,
          alignItems: "center",
        }}
        onPress={() => navigation.navigate(CONTACT_SCREEN)}
      >
        <Text
          style={{
            backgroundColor: "white",
            color: "black",
            paddingHorizontal: 20,
            paddingVertical: 10,
            borderRadius: 10,
            fontWeight: "bold",
          }}
        >
          Contact Now
        </Text>
      </Touchable>
    </View>
  );
};

export default ContactNowButton;
