import React, { useState } from "react";
import { ViewStyle, StyleProp, Pressable } from "react-native";

const Touchable = ({
  children,
  containerStyle,
  onPress,
  disabled,
}: {
  children: React.ReactNode;
  onPress?: (e?: any) => void;
  containerStyle?: StyleProp<ViewStyle>;
  disabled?: boolean;
}) => {
  const [hovered, setHovered] = useState(false);
  return (
    <Pressable
      onHoverIn={() => setHovered(true)}
      onHoverOut={() => setHovered(false)}
      onPress={onPress}
      style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1 }, containerStyle]}
      disabled={disabled}
    >
      {children}
    </Pressable>
  );
};

export default Touchable;
