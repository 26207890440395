import React from "react";
import {
  View,
  Image,
  StyleSheet,
  useWindowDimensions,
  Text,
} from "react-native";
import Touchable from "./Touchable";
import { LABOUR_SCREEN } from "../screens/Labour";
import { useNavigation } from "@react-navigation/native";
import { TRAFFIC_SCREEN } from "../screens/Traffic";

const HomeHeader = () => {
  const width = useWindowDimensions().width;
  const navigation = useNavigation<UseNavigationProps>();

  return (
    <View style={{ position: "relative" }}>
      <View
        style={{
          flexDirection: "row",
          zIndex: 999,
        }}
      >
        <View
          style={{
            flex: 1,
            marginHorizontal: width > 800 ? "10%" : 0,
          }}
        >
          <View
            style={{
              margin: 30,
              height: 600,
              justifyContent: "center",
            }}
          >
            <Text style={{ fontSize: 40, fontWeight: "bold", color: "white" }}>
              ALLSAFE LABOUR
            </Text>
            <Text
              style={{
                fontSize: 28,
                fontWeight: "bold",
                color: "white",
                marginTop: 20,
              }}
            >
              Behind every success is a team of dedicated people.
            </Text>

            <Text
              style={{
                fontSize: 20,
                color: "white",
                marginTop: 20,
              }}
            >
              We excel in delivering top-notch, skilled labor hire solutions for
              Construction, Building, Civil & Rail, Security Services, and
              Traffic Management.
            </Text>

            <View
              style={{
                position: "relative",
                marginTop: 40,
                flexDirection: "row",
              }}
            >
              <Touchable
                onPress={() => navigation.navigate(LABOUR_SCREEN)}
                containerStyle={{
                  borderRadius: 10,
                  paddingHorizontal: 20,
                  paddingVertical: 15,
                  backgroundColor: "rgb(244,147,18)",
                }}
              >
                <Text
                  style={{
                    fontSize: 18,
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Labour
                </Text>
              </Touchable>

              <Touchable
                onPress={() => navigation.navigate(TRAFFIC_SCREEN)}
                containerStyle={{
                  borderWidth: 1,
                  borderRadius: 10,
                  paddingHorizontal: 20,
                  paddingVertical: 15,
                  borderColor: "white",
                  marginLeft: 20,
                }}
              >
                <Text
                  style={{
                    fontSize: 18,
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Traffic Management
                </Text>
              </Touchable>
            </View>
          </View>
        </View>
      </View>

      <Image
        source={{ uri: require("../assets/melb.jpeg") }}
        style={{
          width: "100%",
          position: "absolute",
          top: 0,
          bottom: 0,
        }}
      />
      <View style={styles.overlay} />
    </View>
  );
};

export default HomeHeader;

const styles = StyleSheet.create({
  overlay: {
    height: "100%",
    width: "100%",
    position: "absolute",
    backgroundColor: "rgba(0,0,0,0.5)",
  },
});
