import React from "react";
import {
  View,
  Text,
  useWindowDimensions,
  StyleSheet,
  ScrollView,
} from "react-native";
import ImageTitle from "../components/ImageTitle";
import Touchable from "../components/Touchable";
import Footer from "../components/Footer";
import ContactNowButton from "../components/ContactNowButton";

export const LABOUR_SCREEN = "LABOUR";
const Labour = () => {
  const width = useWindowDimensions().width;
  return (
    <ScrollView style={styles.root}>
      <ImageTitle
        text="Labour Hire Services"
        subtitle="Your Trusted Partner for Skilled Workforce Solutions"
        uri={require("../assets/l1.jpg")}
      />
      <View
        style={{
          marginHorizontal: width > 800 ? "10%" : 0,
          marginTop: 20,
        }}
      >
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          At AllSafe Labour Hire, we specialize in providing exceptional labor
          hire services to a wide range of industries. With a commitment to
          excellence and a focus on delivering skilled, reliable, and efficient
          workforce solutions, we are your premier destination for meeting your
          labor needs.
        </Text>
        <Text
          style={{
            marginHorizontal: 30,
            marginVertical: 10,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Why Choose AllSafe Labour Hire?
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Skilled Workforce: </Text>
          Our extensive pool of skilled workers is meticulously selected,
          trained, and vetted to ensure they meet the highest industry
          standards. Whether you require tradespeople, general laborers, or
          specialized professionals, we've got you covered.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Industry Expertise: </Text>
          With years of experience in labor hire, we understand the unique
          demands and challenges of various industries, including Construction,
          Building, Civil & Rail, Security Services, and Traffic Management.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Flexibility: </Text>
          Whether you need temporary workers for a short-term project or a
          long-term workforce for ongoing operations, we offer flexible staffing
          solutions tailored to your specific requirements.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Safety First: </Text>
          Safety is at the core of everything we do. All our personnel are
          trained to adhere to the strictest safety protocols, ensuring a secure
          working environment for your projects.
        </Text>

        <Text
          style={{
            marginHorizontal: 30,
            marginVertical: 10,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Our Labour Hire Specializations
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Construction Labour: </Text>
          From carpenters to bricklayers and heavy equipment operators, we
          provide the skilled labor force you need to complete construction
          projects on time and within budget.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Building Labour: </Text>
          Our experienced laborers are ready to tackle building projects of any
          scale, ensuring that every aspect of the job is handled with precision
          and professionalism.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Civil & Rail Labour: </Text>
          Infrastructure projects demand specialized skills. We offer a
          dedicated workforce trained to excel in the civil and rail sectors.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Security Personnel: </Text>
          Protecting your assets and people is a top priority. Our security
          services provide highly trained personnel to ensure a safe and secure
          environment.
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          <Text style={{ fontWeight: "bold" }}>Traffic Management:: </Text>
          Efficient traffic management is essential for project success. Our
          team of experts helps you maintain order and safety on the road.
        </Text>

        <Text
          style={{
            marginHorizontal: 30,
            marginVertical: 10,
            fontSize: 16,
            fontWeight: "bold",
          }}
        >
          Contact Us
        </Text>
        <Text
          style={{ marginHorizontal: 30, marginVertical: 10, fontSize: 16 }}
        >
          Ready to streamline your labor hire needs with a partner you can
          trust? Contact AllSafe Labour Hire today to discuss your labor
          requirements, and let us provide the skilled workforce that drives
          your success.
        </Text>
      </View>
      <ContactNowButton />
      <Footer />
    </ScrollView>
  );
};

export default Labour;

const styles = StyleSheet.create({
  root: {
    flex: 1,
    backgroundColor: "white",
  },
});
