export default {
  defaultBackgroundColor: "white",
  primaryColor: "rgb(244,147,18)",
  secondaryColor: "#062743",
  thirdColor: "#969696",
  shadow: {
    shadowColor: "silver",
    shadowOffset: { width: 1, height: 2 },
    shadowOpacity: 0.8,
    shadowRadius: 10,
    elevation: 5,
  },
};
