import React from "react";
import { View, Text, ScrollView, useWindowDimensions } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useAppDispatch, useAppSelector } from "../redux-app/hooks";
import { Formik, FormikHelpers, FormikValues } from "formik";
import * as yup from "yup";
import TextField from "../components/TextField";
import lodash from "lodash";
import FilledButton from "../components/FilledButton";
import FilePicker from "../components/FilePicker";
import { Icon } from "@rneui/themed";
import Touchable from "../components/Touchable";
import { setFullPageLoading } from "../reducers/fullPageLoading";
import axios from "axios";
import endpoint from "../constants/endpoint";
import { updateProfile } from "../thunks/updateProfile";
import { setMember } from "../reducers/member";
import { deleteFile } from "../thunks/deleteFile";
import { useNavigation } from "@react-navigation/native";
import { PROFILE_SCREEN } from "./Profile";

export const EDIT_PROFILE_SCREEN = "EDIT_PROFILE";

const EditProfile = () => {
  const width = useWindowDimensions().width;
  const member = useAppSelector((state) => state.member);
  const dispatch = useAppDispatch();
  const navigation = useNavigation<UseNavigationProps>();

  const submit = async (
    values: FormikValues,
    actions: FormikHelpers<FormikValues>
  ) => {
    console.log(values);
    dispatch(setFullPageLoading(true));

    let formData = new FormData();

    if (values.resume.length > 0) {
      formData.append("resume", values.resume[0]);
    }

    if (values.coverLetter.length > 0) {
      formData.append("coverLetter", values.coverLetter[0]);
    }

    if (values.tickets.length > 0) {
      values.tickets.map((ticket: string | Blob) => {
        formData.append("ticket", ticket);
      });
    }

    let links: {
      resume: string;
      coverLetter: string;
      tickets: string[];
    } = {
      resume: member?.resume!,
      coverLetter: member?.coverLetter || "",
      tickets: member?.tickets || [],
    };

    if (
      values.resume.length > 0 ||
      values.coverLetter.length > 0 ||
      values.tickets.length > 0
    ) {
      const response = await axios.post(
        `${endpoint}/api/files/upload`,
        formData
      );
      links = {
        ...links,
        ...(response as any).data,
      };
    }

    await dispatch(updateProfile({ ...values, ...links }))
      .unwrap()
      .then((res) => {
        dispatch(setFullPageLoading(false));
        actions.resetForm();
        navigation.navigate(PROFILE_SCREEN);
      })
      .catch((e) => {
        console.log(e);
        actions.setErrors(e);
        dispatch(setFullPageLoading(false));
        // delete files if something goes wrong
      });
  };

  const initialValues: FormikValues = {
    email: member?.email,
    phoneNumber: member?.phoneNumber,
    firstName: lodash.upperFirst(member?.firstName),
    lastName: lodash.upperFirst(member?.lastName),
    address: lodash.upperFirst(member?.address),
    city: lodash.upperFirst(member?.city),
    state: lodash.upperFirst(member?.state),
    postcode: member?.postcode,
    tickets: [],
    resume: [],
    coverLetter: [],
  };

  const schema = {
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    phoneNumber: yup
      .string()
      .required("Phone number is required")
      .min(8, "too short")
      .max(12, "too long"),
    email: yup.string().email("Invalid email").required("Email is required"),
    address: yup.string().required("Address is required"),
    city: yup.string().required("City is required"),
    state: yup.string().required("State is required"),
    postcode: yup.string().required("Postcode is required"),
  };

  const validation = yup.object().shape(schema);

  return (
    <SafeAreaView
      edges={["bottom"]}
      style={{ flex: 1, backgroundColor: "white" }}
    >
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          paddingBottom: 50,
          paddingHorizontal: 10,
          marginHorizontal: width > 800 ? "10%" : 0,
        }}
        keyboardDismissMode="on-drag"
      >
        <Formik
          validationSchema={validation}
          onSubmit={submit}
          initialValues={initialValues}
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
        >
          {({ setFieldValue, handleSubmit, values }: any) => {
            console.log(values);
            return (
              <View style={{ marginVertical: 20 }}>
                <TextField name="email" disabled={true} />
                <TextField name="firstName" placeholder="First name" />
                <TextField name="lastName" placeholder="Last name" />
                <TextField name="address" />
                <TextField name="city" />
                <TextField name="state" />
                <TextField name="postcode" />
                <View style={{ marginTop: 10 }}>
                  <Text>Resume</Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {member?.resume && (
                      <Touchable
                        onPress={() => {
                          //@ts-ignore
                          window.open(member?.resume!, "_blank");
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "whitesmoke",
                            padding: 5,
                            borderRadius: 5,
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Icon name="description" size={20} />

                          <Text>
                            {member!.resume.length > 0 && "1 uploaded"}
                          </Text>
                        </View>
                      </Touchable>
                    )}

                    {member!.resume.length > 0 && (
                      <Touchable
                        onPress={async () => {
                          await dispatch(deleteFile(member?.resume!))
                            .then((response) => {
                              dispatch(
                                updateProfile({
                                  ...member,
                                  resume: "",
                                })
                              );
                              setFieldValue("resume", "");

                              dispatch(setFullPageLoading(false));
                            })
                            .catch(() => {
                              dispatch(setFullPageLoading(true));
                            });
                        }}
                      >
                        <Icon name="delete" size={20} />
                      </Touchable>
                    )}
                  </View>

                  {member!.resume.length === 0 && (
                    <>
                      <Text>
                        {values!.resume.length > 0
                          ? "1 selected"
                          : "No file chosen."}
                      </Text>
                      <FilePicker
                        name="resume"
                        setAssets={(files) => {
                          setFieldValue("resume", files);
                        }}
                      />
                    </>
                  )}
                </View>

                <View style={{ marginTop: 10 }}>
                  <Text>Cover Letter</Text>
                  <View
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    {member?.coverLetter && (
                      <Touchable
                        onPress={() => {
                          //@ts-ignore
                          window.open(member?.coverLetter!, "_blank");
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "whitesmoke",
                            padding: 5,
                            borderRadius: 5,
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Icon name="description" size={20} />

                          <Text>
                            {member!.coverLetter.length > 0 && "1 uploaded"}
                          </Text>
                        </View>
                      </Touchable>
                    )}

                    {member!.coverLetter.length > 0 && (
                      <Touchable
                        onPress={async () => {
                          await dispatch(deleteFile(member?.coverLetter!))
                            .then((response) => {
                              dispatch(
                                updateProfile({
                                  ...member,
                                  coverLetter: "",
                                })
                              );
                              setFieldValue("coverLetter", "");

                              dispatch(setFullPageLoading(false));
                            })
                            .catch(() => {
                              dispatch(setFullPageLoading(true));
                            });
                        }}
                      >
                        <Icon name="delete" size={20} />
                      </Touchable>
                    )}
                  </View>

                  {member!.coverLetter.length === 0 && (
                    <>
                      <Text>
                        {values!.coverLetter.length > 0
                          ? "1 selected"
                          : "No file chosen."}
                      </Text>
                      <FilePicker
                        name="coverLetter"
                        setAssets={(files) => {
                          setFieldValue("coverLetter", files);
                        }}
                      />
                    </>
                  )}
                </View>

                <View style={{ marginTop: 10 }}>
                  <Text>Other( Tickets etc.)</Text>
                  {member?.tickets.map((ticket, index) => (
                    <View
                      style={{
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Touchable
                        onPress={() => {
                          //@ts-ignore
                          window.open(ticket, "_blank");
                        }}
                      >
                        <View
                          style={{
                            backgroundColor: "whitesmoke",
                            padding: 5,
                            borderRadius: 5,
                            flexDirection: "row",
                            alignItems: "center",
                          }}
                        >
                          <Icon name="description" size={20} />

                          <Text>{index + 1} ticket</Text>
                        </View>
                      </Touchable>

                      <Touchable
                        onPress={async () => {
                          await dispatch(deleteFile(ticket))
                            .then((response) => {
                              dispatch(
                                updateProfile({
                                  ...member,
                                  tickets: member.tickets.filter(
                                    (each) => each !== ticket
                                  ),
                                })
                              );
                              setFieldValue("tickets", "");

                              dispatch(setFullPageLoading(false));
                            })
                            .catch(() => {
                              dispatch(setFullPageLoading(true));
                            });
                        }}
                      >
                        <Icon name="delete" size={20} />
                      </Touchable>
                    </View>
                  ))}

                  <Text>
                    {values!.tickets.length > 0
                      ? `${values!.tickets.length} selected`
                      : "No file chosen."}
                  </Text>
                  <FilePicker
                    name="tickets"
                    setAssets={(files) => {
                      setFieldValue("tickets", files);
                    }}
                    multiple
                  />
                </View>

                <View style={{ width: 200 }}>
                  <FilledButton onPress={handleSubmit} label="Update" />
                </View>
              </View>
            );
          }}
        </Formik>
      </ScrollView>
    </SafeAreaView>
  );
};

export default EditProfile;
