import { useNavigation } from "@react-navigation/native";
import { ABOUT_SCREEN } from "../screens/About";
import { LABOUR_SCREEN } from "../screens/Labour";
import { TRAFFIC_SCREEN } from "../screens/Traffic";
import { CONTACT_SCREEN } from "../screens/Contact";
import { REGISTER_SCREEN } from "../screens/Register";

const useHeaderMenu = () => {
  const navigation = useNavigation<UseNavigationProps>();

  return [
    {
      text: "About",
      category: "about",
      onPress: () => navigation.navigate(ABOUT_SCREEN),
    },
    {
      text: "Labour",
      category: "labour",
      onPress: () => navigation.navigate(LABOUR_SCREEN),
    },
    {
      text: "Traffic",
      category: "traffic",
      onPress: () => navigation.navigate(TRAFFIC_SCREEN),
    },
    {
      text: "Career",
      category: "career",
      onPress: () => {
        navigation.navigate(REGISTER_SCREEN);
      },
    },
    {
      text: "Contact",
      category: "contact",
      onPress: () => navigation.navigate(CONTACT_SCREEN),
    },
  ];
};

export default useHeaderMenu;
