import { Formik, FormikHelpers, FormikValues } from "formik";
import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import TextField from "./TextField";
import Touchable from "./Touchable";
import themes from "../constants/themes";
import axios from "axios";
import endpoint from "../constants/endpoint";
import * as yup from "yup";

const ContactForm = ({
  whiteBackground = false,
}: {
  whiteBackground?: boolean;
}) => {
  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (showSuccess) {
      setTimeout(() => {
        setShowSuccess(false);
      }, 15000);
    }
  }, [showSuccess]);

  const onSubmit = (
    values: FormikValues,
    helpers: FormikHelpers<FormikValues>
  ) => {
    axios
      .post(`${endpoint}/api/contact/sendMessage`, values)
      .then(() => {
        helpers.setSubmitting(false);
        helpers.resetForm();
        setShowSuccess(true);
      })
      .catch((e) => {
        helpers.setErrors(e.response.data);
        helpers.setSubmitting(false);
      });
  };

  const initialValues: FormikValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
  };

  const schema = {
    firstName: yup.string().required("First name is required"),
    lastName: yup.string().required("Last name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phoneNumber: yup.string().required("Phone number is required"),
  };

  const validation = yup.object().shape(schema);

  return (
    <View
      style={{
        flex: 1,
        alignItems: "center",
      }}
    >
      <View style={{}}>
        <Text
          style={{ fontSize: 30, color: whiteBackground ? "black" : "white" }}
        >
          Need people for your next project?
        </Text>

        <View style={{ marginTop: 20 }}>
          <Formik
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validation}
          >
            {({ handleSubmit, isSubmitting }: any) => (
              <>
                <TextField name="firstName" />
                <TextField name="lastName" />

                <TextField name="email" keyboardType="email-address" />
                <TextField name="phoneNumber" />

                {showSuccess && (
                  <Text style={{ marginVertical: 10, color: "green" }}>
                    Message sent. We'll contact you as soon as possible.
                  </Text>
                )}

                <Touchable
                  onPress={handleSubmit}
                  disabled={isSubmitting}
                  containerStyle={{
                    borderRadius: 10,
                    paddingHorizontal: 20,
                    paddingVertical: 10,
                    backgroundColor: themes.primaryColor,
                    width: 140,
                    marginTop: 10,
                  }}
                >
                  <Text
                    style={{
                      fontSize: 18,
                      color: "white",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    {isSubmitting ? "Submitting" : "Submit"}
                  </Text>
                </Touchable>
              </>
            )}
          </Formik>
        </View>
      </View>
    </View>
  );
};

export default ContactForm;
